"use client";
import Image from "next/image";
import React, { useState } from "react";

const Header = () => {
  // State to manage the visibility of the mobile menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to toggle the mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="relative flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full text-lg uppercase py-4 bg-brown-500 ">
      <nav
        className="max-w-[85rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between"
        aria-label="Global"
      >
        <div className="flex items-center justify-between">
          <a
            className="flex-none text-xl font-semibold dark:text-white"
            href="/"
          >
            <Image
              src="/images/ujs.png"
              className="ml-28 w-36 lg:w-60"
              width={300}
              height={100}
              alt="Logo"
            />
          </a>
          <div className="sm:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="p-2 inline-flex justify-center items-center gap-x-2 rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-gray-700 dark:text-white dark:hover:bg-white/10 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              aria-controls="navbar-collapse-animation"
              aria-expanded={isMenuOpen}
              aria-label="Toggle navigation"
            >
              {isMenuOpen ? (
                <svg
                  className="block flex-shrink-0 w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              ) : (
                <svg
                  className="block flex-shrink-0 w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="3" y1="12" x2="21" y2="12" />
                  <line x1="3" y1="6" x2="21" y2="6" />
                  <line x1="3" y1="18" x2="21" y2="18" />
                </svg>
              )}
            </button>
          </div>
        </div>
        <div
          id="navbar-collapse-animation"
          className={`hs-collapse ${
            isMenuOpen ? "block" : "hidden"
          } overflow-hidden transition-all duration-300 basis-full grow sm:block`}
        >
          <div className="flex flex-col gap-10 mt-5 sm:flex-row sm:items-center sm:justify-end sm:mt-0 sm:gap-12 ">
            <a
              className="underline underline-offset-4 un my-[1px] text-gray-200"
              href="/"
              aria-current="page"
            >
              Home
            </a>
            <a className="text-gray-200" href="/about-us/">
              About us
            </a>
            <a className="text-gray-200" href="/service/">
              OUR OFFERINGS
            </a>
            {/* <a className="text-gray-200" href="/work/">
              WORK
            </a> */}
            <a className="text-gray-200" href="/contact-us/">
              CONTACT US
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
